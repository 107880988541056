/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

button:focus {
    outline: 1px dotted;
    outline: #fff!important;
    cursor: pointer;
}

.panle {
    background: #ffffff;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    position: relative;
    min-height: 250px;
}

.onsale-section {
    position: absolute;
    top: -6px;
    right: 15px;
}

.onsale-section:after {
    position: absolute;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 6px solid #526069;
}

.onsale {
    position: relative;
    display: inline-block;
    text-align: center;
    color: #fff;
    background: #526069;
    font-size: 14px;
    line-height: 1;
    padding: 12px 14px 6px;
    border-top-right-radius: 8px;
    width: 126%;
    text-transform: uppercase;
}

.onsale:before,
.onsale:after {
    position: absolute;
    content: '';
    display: block;
}

.onsale:before {
    background: #6ec5d5;
    height: 7px;
    width: 6px;
    left: -6px;
    top: 0;
}

.onsale:after {
    background: #96a0a2;
    height: 7px;
    width: 8px;
    border-radius: 8px 8px 0 0;
    left: -8px;
    top: 0;
}

.mat-fab .mat-button-wrapper {
    z-index: 999999;
}

mat-label,
input,
mat-checkbox,
textarea,
p,
table,
th,
td,
h1,
.mat-card-title,
.mat-option-text,
.mat-select-value,
.mat-tab-label .mat-tab-label-content,
button,
.onsale,
span,
body,
label,
option,
select,
.mat-tooltip,
.form-control div {
    font-family: 'Droid Arabic Kufi', serif !important;
}

.mat-checkbox-layout{
    white-space: pre-wrap !important;
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
  }

.form-control {
    padding: 0.075rem 0.75rem !important;
}

.btn_float {
    border-radius: 16px !important;
    z-index: 999;
    color: #fff !important;
    background-color: #3f51b5 !important;
    border-color: #3f51b5 !important;
}

.btn_float_new {
    border-radius: 50px 50px !important;
    text-align: center !important;
    box-shadow: 2px 2px 3px !important;
    display: grid !important;
    border-radius: 16px !important;
    z-index: 999;
    color: #fff !important;
    background-color: #3e8ef7 !important;
    border-color: #3e8ef7 !important;
}

.floatcancel {
    /* width: 60px; */
    /* height: 60px; */
    border-radius: 16px !important;
    text-align: center !important;
    /* border-radius: 50px 50px; */
    box-shadow: 2px 2px 3px !important;
    z-index: 999;
    color: #fff !important;
    background-color: #eb6709 !important;
    border-color: #eb6709 !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #3f51b5;
}

.mat-expansion-indicator::after {
    color: #97a3aa;
}

.mat-expansion-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px !important;
    border-radius: inherit;
    transition: height 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mat-expansion-panel {
    display: inline !important;
}

.mat-expansion-panel-body {
    padding: 0px 11px 7px !important;
    background: #00000057;
}

.site-footer {
    height: 44px;
    padding: 10px 30px;
    background-color: rgba(0, 0, 0, .02);
    border-top: 1px solid #e4eaec;
}

.site-footer-legal {
    float: left;
}

.site-footer-right {
    float: right;
}